
import Vue from "vue";
import {mapState} from 'vuex'
import {ObjectUtils} from "~/assets/utils/ObjectUtils";

export default Vue.extend({
	name: 'Navbar',
	computed: {
		...mapState(['idgroup', 'navigation']),
	},
	data: () => ({
		navgroup: []
	}),
	methods: {},
	mounted() {
		/**
		 * @author @Simo mailto:simoneolivieri@kubepartners.com
		 * @description //retrive nagivation array from store and groupby domain
		 * @date 09:55 18/04/23
		 * @param mapState(['idgroup','navigation'])
		 * @return groupByKey
		 **/
		this.navgroup = ObjectUtils.groupByKey(this.navigation['pages'], 'idgroup')
		// console.log(this.navgroup)
	}
})
