
import Vue from "vue";

export default Vue.extend({
	name: 'Logout',
	methods: {
		async logout() {
			await this.$auth.logout().then((res) => {
				this.$router.push('/login')
				this.$cookies.removeAll()
				this.$store.commit('LOADED', false)
				this.$store.commit('SET_PRES_SEL', 0)
				this.$store.commit('SET_PRESETS', [])
				this.$store.commit('SET_DATA', [])
			})
			localStorage.removeItem('vuex')
			localStorage.removeItem('auth._token.local')
		}
	},
	watch: {
		'$route.name'() {
			this.open = false;
		}
	},
});
