export class ObjectUtils {

	static equals(obj1: any, obj2: any, field?: string) {
		if (field) {
			return this.resolveFieldData(obj1, field) === this.resolveFieldData(obj2, field);
		}
		else {
			return this.deepEquals(obj1, obj2);
		}
	}

	static deepEquals(a: any, b: any) {
		if (a === b) return true;

		if (a && b && typeof a == 'object' && typeof b == 'object') {
			let arrA = Array.isArray(a), arrB = Array.isArray(b), i, length, key;

			if (arrA && arrB) {
				length = a.length;
				if (length != b.length) return false;
				for (i = length; i-- !== 0;)
					if (!this.deepEquals(a[i], b[i])) return false;
				return true;
			}

			if (arrA != arrB) return false;

			let dateA = a instanceof Date
				, dateB = b instanceof Date;
			if (dateA != dateB) return false;
			if (dateA && dateB) return a.getTime() == b.getTime();

			let regexpA = a instanceof RegExp
				, regexpB = b instanceof RegExp;
			if (regexpA != regexpB) return false;
			if (regexpA && regexpB) return a.toString() == b.toString();

			let keys = Object.keys(a);
			length = keys.length;

			if (length !== Object.keys(b).length)
				return false;

			for (i = length; i-- !== 0;)
				if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

			for (i = length; i-- !== 0;) {
				key = keys[i];
				if (!this.deepEquals(a[key], b[key])) return false;
			}

			return true;
		}

		return a !== a && b !== b;
	}

	static resolveFieldData(data: any, field: string) {

		if (data && Object.keys(data).length && field) {
			if (this.isFunction(field)) {
				// @ts-ignore
				return field(data);
			}
			else if (field.indexOf('.') === -1) {
				return data[field];
			}
			else {
				let fields = field.split('.');
				let value = data;
				for (let i = 0, len = fields.length; i < len; ++i) {
					if (value == null) {
						return null;
					}
					value = value[fields[i]];
				}
				return value;
			}
		}
		else {
			return null;
		}
	}

	static isFunction(obj: any) {
		return !!(obj && obj.constructor && obj.call && obj.apply);
	}

	static filter(value, fields, filterValue) {
		let filteredItems = [];

		if (value) {
			for (let item of value) {
				for (let field of fields) {
					if (String(this.resolveFieldData(item, field)).toLowerCase().indexOf(filterValue.toLowerCase()) > -1) {
						filteredItems.push(item);
						break;
					}
				}
			}
		}

		return filteredItems;
	}

	static reorderArray(value: any, from: number, to: number) {
		let target;
		if (value && (from !== to)) {
			if (to >= value.length) {
				target = to - value.length;
				while ((target--) + 1) {
					value.push(undefined);
				}
			}
			value.splice(to, 0, value.splice(from, 1)[0]);
		}
	}

	static findIndexInList(value: any, list: any[], dataKey?: string): number {
		let _dataKey = dataKey;
		let index = -1;

		if (list) {
			for (let i = 0; i < list.length; i++) {
				if (list[i] === value) {
					index = i;
					break;
				}
			}
		}

		return index;
	}

	static contains(value: any, list: any[]) {
		if (value != null && list && list.length) {
			for (let val of list) {
				if (this.equals(value, val))
					return true;
			}
		}

		return false;
	}

	static insertIntoOrderedArray(item: any, index: number, arr: any[], sourceArr: any[]) {
		if (arr.length > 0) {
			let injected = false;
			for (let i = 0; i < arr.length; i++) {
				// @ts-ignore
				let currentItemIndex = this.findIndexInList(arr[i], sourceArr);
				if (currentItemIndex > index) {
					arr.splice(i, 0, item);
					injected = true;
					break;
				}
			}

			if (!injected) {
				arr.push(item);
			}
		}
		else {
			arr.push(item);
		}
	}

	static removeAccents(str: any) {
		if (str && str.search(/[\xC0-\xFF]/g) > -1) {
			str = str
				.replace(/[\xC0-\xC5]/g, "A")
				.replace(/[\xC6]/g, "AE")
				.replace(/[\xC7]/g, "C")
				.replace(/[\xC8-\xCB]/g, "E")
				.replace(/[\xCC-\xCF]/g, "I")
				.replace(/[\xD0]/g, "D")
				.replace(/[\xD1]/g, "N")
				.replace(/[\xD2-\xD6\xD8]/g, "O")
				.replace(/[\xD9-\xDC]/g, "U")
				.replace(/[\xDD]/g, "Y")
				.replace(/[\xDE]/g, "P")
				.replace(/[\xE0-\xE5]/g, "a")
				.replace(/[\xE6]/g, "ae")
				.replace(/[\xE7]/g, "c")
				.replace(/[\xE8-\xEB]/g, "e")
				.replace(/[\xEC-\xEF]/g, "i")
				.replace(/[\xF1]/g, "n")
				.replace(/[\xF2-\xF6\xF8]/g, "o")
				.replace(/[\xF9-\xFC]/g, "u")
				.replace(/[\xFE]/g, "p")
				.replace(/[\xFD\xFF]/g, "y");
		}

		return str;
	}

	static getVNodeProp(vnode: any, prop: any) {
		let props = vnode._props;
		if (props) {
			let kebapProp = prop.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
			let propName = Object.prototype.hasOwnProperty.call(props, kebapProp) ? kebapProp : prop;

			return props[propName];
		}

		return null;
	}

	static lowerCase(arr: any[]) {
		return arr.map(x => Object.fromEntries(
			Object.entries(x).map(
				([key, value]) => [key, typeof value == 'string' ? value.toLowerCase() : value])
		));
	}

	static upperCase(arr: any[]) {
		return arr.map(x => Object.fromEntries(
			Object.entries(x).map(
				([key, value]) => [key, typeof value == 'string' ? value.toUpperCase() : value])
		));
	}

	static sortArray(arr: any[]) {
		return new Array(arr.length).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
	}

	static groupByKey(array: any, key: string) {
		return array.reduce((hash, obj) => {
			if (obj[key] === undefined) return hash;
			return Object.assign(hash, {[obj[key]]: (hash[obj[key]] || []).concat(obj)})
		}, {})
	}

	static chunkArray(arr: any [], size: number) {
		return arr.reduce((acc, _, i) => (i % size) ? acc : [...acc, arr.slice(i, i + size)], [])
	}

	static fillArrayWithNull(arr: any[], length: number): any[] {
		if (length > arr.length) {
			let newArr: any[] = arr;
			let nullArr: any[] = Array(length - arr.length).fill(null);
			newArr = newArr.concat(nullArr);
			return newArr;
		}
		else if (length == arr.length) {
			return arr;
		}
		else {
			console.log('length is smaller than the array length')
		}
	}

	static fillNestedArrayWithNull(nestedArr: any[][], length: number): any[][] {
		let newNestedArr = [...nestedArr];
		newNestedArr.forEach((arr, i) => {
			if (length > arr.length) {
				let nullArr = Array(length - arr.length).fill(null);
				newNestedArr[i] = arr.concat(nullArr);
			}
			else if (length == arr.length) {
				return;
			}
			else {
				console.log('length is smaller than the array length at index' + i);
			}
		});
		return newNestedArr;
	}

	static moveArrayValues(arr: any[], newPos: number): any[] {
		let start: number = arr.findIndex(val => val !== null);
		let end: number = arr.lastIndexOf(arr.filter(val => val !== null)[arr.filter(val => val !== null).length - 1]);
		// console.log(start, end)
		let movingValues = arr.slice(start, end + 1);
		let newArr = [...arr.slice(0, start), ...arr.slice(end + 1)];
		newArr.splice(newPos, 0, ...movingValues);
		return newArr;
	}

	static moveGroupInNestedArray(nestedArr: any[][], newPosition: number): any[][] {
		let newNestedArr = [...nestedArr];
		newNestedArr.forEach((arr, i) => {

			let start: number = nestedArr.findIndex(val => val !== null);
			let end: number = nestedArr.lastIndexOf(nestedArr.filter(val => val !== null)[nestedArr.filter(val => val !== null).length - 1]);

			let groupToMove = arr.slice(start, end + 1);
			if (newPosition > arr.length) {
				console.log('New position is out of range of the array.');
				return;
			}
			else if (newPosition > end) {
				newNestedArr[i] = arr.slice(0, start).concat(arr.slice(end + 1, newPosition)).concat(groupToMove).concat(arr.slice(newPosition));
			}
			else if (newPosition < start) {
				newNestedArr[i] = arr.slice(0, newPosition).concat(groupToMove).concat(arr.slice(newPosition, start)).concat(arr.slice(end + 1));
			}
			else {
				console.log('Invalid new position. New position should be different than the current position of the group.');
			}
		});
		return newNestedArr;
	}


	static findIndexByValue(arr: any[], value: any): number {
		let index = -1;
		arr.some((item, i) => {
			if (item === value) {
				index = i;
				return true;
			}
		});
		return index;
	}

	static removeDuplicates(arr: any[]) {
		return [...new Set(arr)];
	}

	static sortByKey(array: any [], key: string) {
		return array.sort(function (a, b) {
			let x = a[key]
			let y = b[key]
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		})
	}

	static normalise(arr: number [], pos: number) {
		let w = [];
		let c = arr[pos]
		arr.forEach((v, i) => {
			let res = isFinite(100 * v / c) ? 100 * v / c : null
			w.push(res);
		})
		// console.log(w)
		return w;
	}

	static groupByLevel(arr: any[], levelKey: string, relatedKey: string) {
		return Object.values(
			arr?.reduce((groups, item) => {
				const groupKey = item[levelKey];
				if (!groups[groupKey]) {
					groups[groupKey] = {[levelKey]: groupKey, [relatedKey]: []};
				}
				groups[groupKey][relatedKey].push(item);
				return groups;
			}, {})
		);
	}
}
