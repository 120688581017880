
import Vue from "vue";
import {mapState} from 'vuex'
import Logo from "~/assets/components/gui/brand/Logo.vue";

export default Vue.extend({
	name: 'grid',
	components: {Logo},
	data: () => ({
		loading: true
	}),
	computed: {
		...mapState(['isfilters', 'isdatatable', 'navigation'])
	},
	methods: {
		noFilters() {
			let route = this.$route.path
			return [
				route === '/comp_cls' ||
				route === '/prod_time'
						? 'noFilters' : 'withFilters'
			]
		}
	},
	beforeCreate() {
		// @ts-ignore
		this.loading = true
	},
	mounted() {
		this.loading = false
	}
});
