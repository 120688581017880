
import Vue from "vue";
import {mapState} from 'vuex'
import {ObjectUtils} from "~/assets/utils/ObjectUtils";

export default Vue.extend({
	name: 'Sidebar',
	computed: {
		...mapState(['idgroup', 'navigation', 'domain']),
	},
	data: () => ({
		loaded: false,
		nav_group: [],
		group_key: [],
		group_path: []
	}),
	methods: {
		buildNav() {
			/**
			 * @author @Simo mailto:simoneolivieri@kubepartners.com
			 * @description //retrive nagivation array from store and groupby domain
			 * @date 09:53 18/04/23
			 * @param mapState(['navigation'])
			 * @return groupByKey
			 **/
			this.nav_group = ObjectUtils.groupByKey(this.navigation['pages'], 'idgroup')
			this.group_key = Object.keys(this.nav_group)

			Object.entries(this.nav_group).forEach((v, i) => {
				let paths = v.slice(-1).pop()
				this.group_path.push(paths[0].path)
			})
		}
	},
	mounted() {
		this.loaded = true
		this.buildNav()
	}
})
