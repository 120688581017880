import axios from 'axios'

/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description define class UrlCollection for building collection
 * @date 15:28 08/03/2022
 * @param <T> (generic)
 * @functions constructor(), getKey(), getValues(), urlBuild(a), getData()
 * @return constructor for @param
 * @revision 09:53 10/03/2022  @author simoneolivieri @description add function inside class
 **/
export class UrlCollection<T> {
	public items: T

	// @ts-ignore
	constructor(...args: T) {
		this.items = args
	}

	// get keys of object
	getKeys(this) {
		return Object.keys(this.items);
	}

	// get values of object
	getValues(this) {
		return Object.values(this.items);
	}

	/**
	 * @author @Simo mailto:simoneolivieri@kubepartners.com
	 * @description build url pair url key, collection value
	 * @date 09:50 10/03/2022
	 * @param a example a = url_key = new UrlCollection<Urlkey> from "~/api/kteData";
	 * @return a + values
	 **/
	urlBuild(a) {
		let results: any = [];
		let values = this.getValues();

		// check if there are undefined values, remove key & values from @params
		let checkUndefined = () => {
			values.forEach((value, index) => {
				if (value === undefined || null) {
					let to_remove = index;
					// console.log(to_remove);
					if (a.length == values.length) {
						a.splice(to_remove, 1);
					}
					values.splice(to_remove, 1);
				}
			});
		}

		// merge @param pair key|value
		let mergeArray = () => {
			a.forEach((v, i) => {
				if (a.length == values.length) {
					results.push(
						v + values[i]
					);
				}
				else {
					console.log('not pair key|values:' + a.length, values.length);
				}
			});
			// console.log(results);
		}

		checkUndefined();
		mergeArray();

		return results.join('');
	}
}

/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description fetch data whith axios
 * @date 16:37 08/03/2022
 * @param arr = array to push data
 * @param url = api get
 * @param level = level object
 * @param token
 * @return arr = array of data from url and level
 * @revision 15:54 10/03/2022  @author simoneolivieri @description while => empty array before push
 * @revision 09:07 18/03/2022  @author simoneolivieri @description lambda function
 **/
export const getData = async (url: any, level: string | undefined, arr: any[], token: string) => {
	while (arr.length > 0) {
		arr.pop()
	}
	await axios.get(
		url,
		{
			headers: {
				Authorization: token
			}
		}
	)
		.then(res => {
			if (level === undefined) {
				arr.push(res.data)

			}
			else {
				res.data[level].forEach((v) => {
					arr.push(v)
				})
			}
		})
		.catch(async errors => {
			console.error(errors)
		})
}

/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description  post data whith axios
 * @date 12:38 03/02/23
 * @param url = api post
 * @param data = data object
 * @param token
 **/
export const postData = async (url: any, data: any, token: string) => {
	await axios.post(
		url,
		data,
		{
			headers: {Authorization: token}
		}
	)
		.then(
			// console.log
		)
		.catch(
			console.log
		)
}
/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description  delete data whith axios
 * @date 12:38 03/02/23
 * @param url = api del
 * @param data
 * @param token
 **/
export const delData = async (url: any, token: string, data: any | undefined,) => {
	await axios.delete(url, {
		headers: {
			Authorization: token
		},
		data: data
	})
		.then(
			// console.log
		)
		.catch(
			console.log
		)
}
/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description //update data
 * @date 16:58 03/05/23
 * @param url = api update
 * @param data
 * @param token
 **/
export const upData = async (url: any, data: any, token: string) => {
	await axios.put(
		url,
		data,
		{
			headers: {Authorization: token}
		}
	)
		.then(
			// console.log
		)
		.catch(
			console.log
		)
}
/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description //store data single url
 * @date 10:31 19/04/23
 * @param commit
 * @param url
 * @param token
 * @param mutation
 * @return data mutation
 **/

export const storeData = async (commit, url: string, token: string, mutation) => {
	await axios.get(
		url,
		{
			headers: {
				Authorization: token
			}
		}
	)
		.then(res => {
			commit(mutation, res.data)
		})
		.catch(async errors => {
			console.error(errors)
		})
}

export const getWithQuery = async (url: string, data: any | undefined, arr: any[], token: string) => {
	await axios.post(
		url,
		data,
		{
			headers: {
				Authorization: token
			}
		}
	)
		.then(res => {
			// console.log(res)
			arr.push(res.data)
		})
		.catch(async errors => {
			console.error(errors)
		})
}
