/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description //import pagelist navigation
 * @date 09:40 18/04/23
 * @param ~/nav.json
 * @return pagelist
 **/
import pagelist from '~/nav.json';


/**
 * @author @Simo mailto:simoneolivieri@kubepartners.com
 * @description server side store filters api
 * @date 08:55 25/03/2022
 * @revision 15:07 25/03/2022  @author simoneolivieri @description import storeFilters
 **/
export const state = () => ({
	navigation: [],
	idgroup: null,
	filtersdata: [],
	presets: [],
	companydata: [],
	loaded: false,
	isfilters: false,
	isdatatable: false,
	role: null,
	preset_selected: 0
})

export const mutations = {
	SET_NAVIGATION(state, navigation) {
		state.navigation = navigation
	},
	SET_GROUP_ID(state, id) {
		state.idgroup = id;
	},
	SET_FILTERS(state, filters) {
		state.filtersdata = filters
	},
	SET_PRESETS(state, presets) {
		state.presets = presets
	},
	SET_DATA(state, data) {
		// state.urldata.push(url)
		// otherwise only one
		state.companydata = data
		// state.companydata.push(data)
	},
	LOADED(state, loaded) {
		state.loaded = loaded
	},
	IS_FILTERS(state, active) {
		state.isfilters = active;
	},
	IS_DATATABLE(state, active) {
		state.isdatatable = active;
	},
	SET_ROLE(state, role) {
		state.role = role
	},
	SET_PRES_SEL(state, sel) {
		state.preset_selected = sel
	}
}

export const actions = {
	async nuxtServerInit({commit}, {store}) {
		/**
		 * @author @Simo mailto:simoneolivieri@kubepartners.com
		 * @description //store navigation on server init
		 * @date 09:40 18/04/23
		 * @param SET_NAVIGATION
		 * @return navigation
		 **/
		commit('SET_NAVIGATION', pagelist)
		console.log('navigation stored')
	}
}

export const getters = {
	isAuthenticated(state) {
		return state.auth.loggedIn
	},

	loggedInUser(state) {
		return state.auth.user
	}
}
