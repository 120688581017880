import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c3d45bbe = () => interopDefault(import('../pages/bkp_prm_breed.vue' /* webpackChunkName: "pages/bkp_prm_breed" */))
const _012e9f51 = () => interopDefault(import('../pages/comp_cls.vue' /* webpackChunkName: "pages/comp_cls" */))
const _3511479e = () => interopDefault(import('../pages/down_files.vue' /* webpackChunkName: "pages/down_files" */))
const _32a84156 = () => interopDefault(import('../pages/first_prm.vue' /* webpackChunkName: "pages/first_prm" */))
const _5e7cfc22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1e3b4b7e = () => interopDefault(import('../pages/map_prm.vue' /* webpackChunkName: "pages/map_prm" */))
const _47f56546 = () => interopDefault(import('../pages/market_price.vue' /* webpackChunkName: "pages/market_price" */))
const _dfcd8620 = () => interopDefault(import('../pages/mom_prm.vue' /* webpackChunkName: "pages/mom_prm" */))
const _6b28c399 = () => interopDefault(import('../pages/prm_breed.vue' /* webpackChunkName: "pages/prm_breed" */))
const _6730cafa = () => interopDefault(import('../pages/prm_comp.vue' /* webpackChunkName: "pages/prm_comp" */))
const _4f135b42 = () => interopDefault(import('../pages/prm_prod.vue' /* webpackChunkName: "pages/prm_prod" */))
const _13b825e1 = () => interopDefault(import('../pages/prod_compar.vue' /* webpackChunkName: "pages/prod_compar" */))
const _4ea434ee = () => interopDefault(import('../pages/prod_time.vue' /* webpackChunkName: "pages/prod_time" */))
const _2a8bb40c = () => interopDefault(import('../pages/rnk_ot.vue' /* webpackChunkName: "pages/rnk_ot" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bkp_prm_breed",
    component: _c3d45bbe,
    name: "bkp_prm_breed___en"
  }, {
    path: "/comp_cls",
    component: _012e9f51,
    name: "comp_cls___en"
  }, {
    path: "/down_files",
    component: _3511479e,
    name: "down_files___en"
  }, {
    path: "/first_prm",
    component: _32a84156,
    name: "first_prm___en"
  }, {
    path: "/login",
    component: _5e7cfc22,
    name: "login___en"
  }, {
    path: "/map_prm",
    component: _1e3b4b7e,
    name: "map_prm___en"
  }, {
    path: "/market_price",
    component: _47f56546,
    name: "market_price___en"
  }, {
    path: "/mom_prm",
    component: _dfcd8620,
    name: "mom_prm___en"
  }, {
    path: "/prm_breed",
    component: _6b28c399,
    name: "prm_breed___en"
  }, {
    path: "/prm_comp",
    component: _6730cafa,
    name: "prm_comp___en"
  }, {
    path: "/prm_prod",
    component: _4f135b42,
    name: "prm_prod___en"
  }, {
    path: "/prod_compar",
    component: _13b825e1,
    name: "prod_compar___en"
  }, {
    path: "/prod_time",
    component: _4ea434ee,
    name: "prod_time___en"
  }, {
    path: "/rnk_ot",
    component: _2a8bb40c,
    name: "rnk_ot___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
